export enum ColorScheme {
  NONE,
  BLUE,
  GREEN,
  BEIGE,
  YELLOW,
  PURPLE,
  RED,
  PINK,
}
