import React, { useEffect } from 'react';
import './Imprint.css';
import { ActiveNav } from '../../types/navigation/ActiveNav';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const Imprint: React.FC<Props> = ({ setActiveNav }: Props) => {
  useEffect(() => {
    setActiveNav(ActiveNav.IMPRINT);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <div className='Imprint'>
      <Helmet>
        <title>Imprint - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='ImprintInner'>
        <div className='content-container'>
          <div className='german-container'>
            <h1>Impressum</h1>
            <p>
              Verantwortlich für den Inhalt:
              <br />
              Martina Junker
              <br />
              Gorch-Fock-Kehre 20
              <br />
              25451 Quickborn
              <br />
              Deutschland
              <br />
              Vertretungsberechtigt: Martina Junker
              <br />
              E-Mail: mail@majunkerart.com
              <br />
              Steuer-Nr.:13/126/03381
              <br />
              Konzept & Design: Martina Junker
              <br />
              Fotos der Kunstwerke: Martina Junker
              <br />
            </p>
            <h2>Copyright</h2>
            <p>
              Sämtliche Texte, Bilder und andere in der Webseite
              veröffentlichten Informationen unterliegen – sofern nicht anders
              gekennzeichnet – dem Copyright von Martina Junker, Quickborn.
            </p>
            <p>
              Jeder Vervielfältigung, Verbreitung, Speicherung und Wieder- bzw.
              Weitergabe der Inhalte ist, ohne schriftliche Genehmigung von
              Martina Junker, Quickborn, untersagt. Das Landgericht Hamburg hat
              mit Urteil vom 12.05.1998 entschieden, dass man durch die
              Anbringung bzw. Ausbringung von Links, die Inhalte der gelinkten
              Seite ggf. mit zu verantworten hat. Dies kann – so das Landgericht
              Hamburg – nur dadurch verhindert werden, dass man sich
              ausdrücklich von den Inhalten der gelinkten Seite, bzw. des
              gesamten Web’s distanziert. Für alle diese Links gilt:
            </p>
            <p>
              Ich betone ausdrücklich, dass ich keinerlei Einfluss auf die
              Gestaltung und Inhalte der gelinkten Webseiten habe. Daher
              distanziere ich mich ausdrücklich von allen Inhalten aller
              gelinkten Webseiten auf meiner gesamten Webseite inkl. aller
              Unterseiten und deren Unterlinks oder anderen
              Weiterleitungsmechanismen. Diese Erklärung gilt für alle, auf
              meiner Homepage ausgebrachten Links und für alle Inhalte der
              Seiten, zu denen Links, Banner oder sonstige Verknüpfungen führen.
            </p>
          </div>
          <div className='english-container'>
            <h1>Imprint</h1>
            <p>
              Responsible for the content:
              <br />
              Martina Junker
              <br />
              Gorch-Fock-Kehre 20
              <br />
              25451 Quickborn
              <br />
              Germany
              <br />
              Authorized to represent: Martina Junker
              <br />
              E-Mail: mail@majunkerart.com
              <br />
              Tax-Nr.:13/126/03381
              <br />
              Concept & Design: Martina Junker
              <br />
              Photos of the artwork: Martina Junker
              <br />
            </p>
            <h2>Copyright</h2>
            <p>
              All texts, pictures and other information published on the website
              are subject to the copyright of Martina Junker, Quickborn, unless
              otherwise indicated.
            </p>
            <p>
              Any duplication, distribution, storage, reproduction or
              transmission of the contents without the written permission of
              Martina Junker, Quickborn, is prohibited.The district court of
              Hamburg has decided with judgment from 12.05.1998 that one has to
              if necessary also answer for contents of the linked side by the
              mounting and/or yield of links. This can be prevented – according
              to the district court of Hamburg – only by the fact that one
              expressly dissociates himself from the contents of the linked
              page, or the entire web. For all these links applies:
            </p>
            <p>
              I expressly emphasize that I have no influence on the design and
              content of the linked websites. Therefore I dissociate myself
              expressly from all contents of all linked web pages on my entire
              web page including all sub pages and their sub links or other
              forwarding mechanisms. This declaration applies to all links on my
              homepage and to all contents of the pages to which links, banners
              or other links lead.
            </p>
          </div>
        </div>
        <div className='copyright-hint'>
          <span>Martina Junker © Copyright ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
