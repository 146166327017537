import React from 'react';
import './HomePaintingCardTwo.css';
import { Painting } from '../../../../types/painting/Painting';

type Props = {
  painting: Painting;
  index: number;
};

const HomePaintingCardTwo: React.FC<Props> = ({ painting, index }: Props) => {
  return (
    <div className='HomePaintingCardTwo'>
      <div
        className={`painting-container ${
          index % 2 === 0 ? 'append-right' : ''
        }`}
      >
        <div className='real-painintg-container'>
          <img
            src={
              'https://res.cloudinary.com/drgz54gpg/w_600/' +
              painting.imageid +
              '.jpg'
            }
            alt={painting.nameLatin}
            className='painting-image'
          />
        </div>

        <div className='painting-name'>
          <span>{painting.nameLatin}</span>
        </div>
      </div>
    </div>
  );
};

export default HomePaintingCardTwo;
