import React from 'react';
import './PaintingCard.css';
import { Painting } from '../../../../types/painting/Painting';
import { SizeType } from '../../../../types/painting/SizeType';
import { CanvasType } from '../../../../types/painting/CanvasType';

type Props = {
  painting: Painting;
};

const PaintingCard: React.FC<Props> = ({ painting }: Props) => {
  // const getImageSize = () => {
  //   switch (painting.size) {
  //     case SizeType.EIGHTY: {
  //       return 'percent100';
  //     }
  //     case SizeType.SIXTY: {
  //       return 'percent90';
  //     }
  //     case SizeType.FIFTY: {
  //       return 'percent85';
  //     }
  //     case SizeType.FOURTY: {
  //       return 'percent80';
  //     }
  //     case SizeType.THIRTY: {
  //       return 'percent75';
  //     }
  //     case SizeType.FIFTEEN: {
  //       return 'percent675';
  //     }
  //   }
  // };

  const getCanvas = () => {
    switch (painting.canvas) {
      case CanvasType.ACRYLIC: {
        return 'Acrylic on canvas';
      }
      case CanvasType.ACRYLICANDOILSTICK: {
        return 'Acrylic + oil stick on canvas';
      }
    }
  };

  const getSize = () => {
    switch (painting.size) {
      case SizeType.FIFTEEN: {
        return '15x15x1.5 cm';
      }
      case SizeType.THIRTY: {
        return '30x30x4.5 cm';
      }
      case SizeType.FOURTY: {
        return '40x40x4.5 cm';
      }
      case SizeType.FIFTY: {
        return '50x50x4.5 cm';
      }
      case SizeType.SIXTY: {
        return '60x60x4.5 cm';
      }
      case SizeType.EIGHTY: {
        return '80x80x4.5 cm';
      }
    }
  };

  return (
    <div className='PaintingCard'>
      <div className='painting-container'>
        <img
          src={
            'https://res.cloudinary.com/drgz54gpg/w_600/' +
            painting.imageid +
            '.jpg'
          }
          alt={painting.nameLatin}
          className='painting-image'
        />
      </div>
      <div className='text-container'>
        <div className='text-wrapper'>
          <span className='card-header-text'>{painting.nameLatin}</span>
        </div>
        <div className='text-wrapper'>
          <span className='card-cursive-text'>{painting.name}</span>
        </div>
        <div className='text-wrapper'>
          <span className='card-normal-text'>{getCanvas()}</span>
        </div>
        <div>
          <span className='card-normal-text'>{getSize()}</span>
        </div>
      </div>
    </div>
  );
};

export default PaintingCard;
