import React, { useCallback, useEffect, useState } from 'react';
import './Paintings.css';
import { ActiveNav } from '../../types/navigation/ActiveNav';
import { Painting } from '../../types/painting/Painting';
import { GetPaintingsResult } from '../../types/painting/GetPaintingsResult';
import { ApiService } from '../../services/Api-Service';
import { ColorScheme } from '../../types/painting/ColorScheme';
import PaintingsInfiniteScroll from '../../components/organisms/paintings/paintingsInfiniteScroll/PaintingsInfiniteScroll';
import ColorDropdown from '../../components/atoms/colorDropdown/ColorDropdown';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const Paintings: React.FC<Props> = ({ setActiveNav }: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [paintings, setPaintings] = useState<Painting[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const getURLColor = () => {
    let urlColor = searchParams.get('color');

    let urlCheckedColor: number | undefined =
      urlColor != null &&
      parseInt(urlColor) &&
      parseInt(urlColor) <= 7 &&
      parseInt(urlColor) >= 0
        ? parseInt(urlColor)
        : undefined;

    return urlCheckedColor ? urlCheckedColor : 0;
  };

  const [color, setColor] = useState<ColorScheme>(getURLColor());

  useEffect(() => {
    setActiveNav(ActiveNav.PAINTINGS);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const getMorePaintings = useCallback(
    async (
      currentPaintings: Painting[],
      newColor: ColorScheme,
      isInitial: boolean,
      time: number
    ) => {
      const res: GetPaintingsResult | null = await ApiService.getPaintings(
        time,
        newColor,
        9
      );

      if (res) {
        if (res.paintings.length < 9) {
          setHasMore(false);
        }

        if (isInitial) {
          setPaintings(res.paintings);
        } else {
          let tempPaintings: Painting[] = [];

          for (const tempPainting of currentPaintings) {
            tempPaintings.push(tempPainting);
          }

          for (const tempPainting of res.paintings) {
            tempPaintings.push(tempPainting);
          }

          setPaintings(tempPaintings);
        }
      } else {
        setHasMore(false);
      }
    },
    []
  );

  useEffect(() => {
    let urlColor = searchParams.get('color');

    let urlCheckedColor: number | undefined =
      urlColor != null &&
      parseInt(urlColor) &&
      parseInt(urlColor) <= 7 &&
      parseInt(urlColor) >= 0
        ? parseInt(urlColor)
        : undefined;

    setColor(urlCheckedColor ? urlCheckedColor : 0);

    getMorePaintings(
      [],
      urlCheckedColor ? urlCheckedColor : 0,
      true,
      Date.now()
    );
  }, [searchParams, getMorePaintings]);

  // useEffect(() => {
  //   // console.log('heho');
  //   if (paintings.length === 0) {
  //     getMorePaintings(color, true, Date.now());
  //   }
  // }, [paintings.length, color, getMorePaintings]);

  // useEffect(() => {
  //   console.log('heho');
  //   if (paintings.length === 0) {
  //     getMorePaintings(ColorScheme.NONE, true, Date.now());
  //   }
  // }, [paintings.length, getMorePaintings]);

  const changeColor = (newColor: ColorScheme) => {
    if (color !== newColor) {
      setHasMore(true);
      navigate(
        '/paintings' +
          (newColor !== ColorScheme.NONE ? '?color=' + newColor : '')
      );
      // getMorePaintings([], newColor, true, Date.now());
    }
  };

  return (
    <div className='Paintings'>
      <Helmet>
        <title>Paintings - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='PaintingsInner'>
        <div className='top-text-container'>
          <p className='top-text'>
            Bei einem Atelierbesuch in Quickborn, nahe Hamburg, können Sie sich
            in Ruhe die Originale ansehen. Klicken Sie auf{' '}
            <Link to='/contact' target='__blank' className='text-link'>
              CONTACT
            </Link>{' '}
            um einen Termin zu vereinbaren.
          </p>
          <p className='top-text'>
            During a studio visit in Quickborn, near Hamburg, you can view the
            originals at your leisure. Click on{' '}
            <Link to='/contact' target='__blank' className='text-link'>
              CONTACT
            </Link>{' '}
            to make an appointment.
          </p>
        </div>
        <div className='color-selector-container'>
          <ColorDropdown
            changeColor={changeColor}
            selectedValue={color}
            className='color-selector'
          />
        </div>
        <div></div>
        <PaintingsInfiniteScroll
          paintings={paintings}
          hasMore={hasMore}
          color={color}
          getMorePaintings={getMorePaintings}
        />
      </div>
    </div>
  );
};

export default Paintings;
