import React from 'react';

enum Viewport {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

const breakpoints = {
  MOBILE: 767,
};

interface ViewportProps {
  viewport: Viewport;
}

type ViewportContextProps = {
  children?: React.ReactNode;
};

export const ViewportContext = React.createContext<ViewportProps>(
  {} as ViewportProps
);

const ViewportProvider: React.FC<ViewportContextProps> = ({
  children,
}: ViewportContextProps) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  const viewport: () => Viewport = () => {
    if (width <= breakpoints.MOBILE) return Viewport.MOBILE;
    return Viewport.DESKTOP;
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ viewport: viewport() }}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
export { Viewport };
