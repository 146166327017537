export enum ActiveNav {
  HOME,
  PAINTINGS,
  ABOUT,
  NEWSLETTER,
  SHOP,
  CONTACT,
  IMPRINT,
  PRIVACYPOLICY,
  NONE,
}
