import React from 'react';
import './TextAreaVerificationInput.css';

type Props = {
  className?: string;
  value: string;
  placeholder: string;
  name: string;
  errorMessage: string;
  onChange: (e: any) => void;
};

const TextAreaVerificationInput: React.FC<Props> = ({
  className,
  value,
  name,
  placeholder,
  errorMessage,
  onChange,
}: Props) => {
  return (
    <div className={`TextAreaVerificationInput ${className ? className : ''}`}>
      <div
        className={`inner-container ${errorMessage !== '' ? 'has-error' : ''}`}
      >
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <span className='error-message'>{errorMessage}</span>
      </div>
    </div>
  );
};

export default TextAreaVerificationInput;
