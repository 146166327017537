import React from 'react';
import './ColorDropdown.css';

import Select from 'react-select';

import { ColorScheme } from '../../../types/painting/ColorScheme';

type Props = {
  className?: string;
  selectedValue: ColorScheme;
  changeColor: (x: ColorScheme) => void;
};

type SelectOption = {
  value: ColorScheme;
  label: string;
};

const ColorDropdown: React.FC<Props> = ({
  className,
  selectedValue,
  changeColor,
}: Props) => {
  const getSelection = (x: ColorScheme) => {
    switch (x) {
      case ColorScheme.NONE: {
        return { value: ColorScheme.NONE, label: 'All' };
      }
      case ColorScheme.BLUE: {
        return { value: ColorScheme.BLUE, label: 'Blueish' };
      }
      case ColorScheme.GREEN: {
        return { value: ColorScheme.GREEN, label: 'Greenish' };
      }
      case ColorScheme.BEIGE: {
        return { value: ColorScheme.BEIGE, label: 'Beigeish' };
      }
      case ColorScheme.YELLOW: {
        return { value: ColorScheme.YELLOW, label: 'Yellowish' };
      }
      case ColorScheme.PURPLE: {
        return { value: ColorScheme.PURPLE, label: 'Purplish' };
      }
      case ColorScheme.RED: {
        return { value: ColorScheme.RED, label: 'Reddish' };
      }
      case ColorScheme.PINK: {
        return { value: ColorScheme.PINK, label: 'Pinkish' };
      }
    }
  };

  // const [selection, setSelection] = useState({
  //   value: ColorScheme.NONE,
  //   label: 'All',
  // });
  const triggerChange = (newValue: any) => {
    changeColor(newValue.value);
    // setSelection(newValue);
  };

  const options: SelectOption[] = [
    { value: ColorScheme.NONE, label: 'All' },
    { value: ColorScheme.BLUE, label: 'Blueish' },
    { value: ColorScheme.GREEN, label: 'Greenish' },
    { value: ColorScheme.BEIGE, label: 'Beigeish' },
    { value: ColorScheme.YELLOW, label: 'Yellowish' },
    { value: ColorScheme.PURPLE, label: 'Purplish' },
    { value: ColorScheme.RED, label: 'Reddish' },
    { value: ColorScheme.PINK, label: 'Pinkish' },
  ];

  return (
    <div className={`ColorDropdown ${className ? className : ''}`}>
      <Select
        defaultValue={getSelection(selectedValue)}
        onChange={triggerChange}
        options={options}
        isSearchable={false}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        styles={{
          option: (provided, state) => ({
            color: state.isSelected ? '#FFFFFF' : 'inherit',
            padding: '8px 12px',
            cursor: 'pointer',
            backgroundColor: state.isSelected
              ? '#abc04d'
              : state.isFocused
              ? 'rgba(171, 192, 77, 0.4)'
              : 'inherit',
          }),
        }}
      />
    </div>
  );
};

export default ColorDropdown;
