import React, { useState } from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';

// Components
import Navigation from './components/organisms/navigation/Navigation';

// Types
import { ActiveNav } from './types/navigation/ActiveNav';
import Home from './pages/home/Home';
import Footer from './components/organisms/footer/Footer';
import About from './pages/about/About';
import Paintings from './pages/paintings/Paintings';
import Newsletter from './pages/newsletter/Newsletter';
import NewsletterUnsubscribe from './pages/newsletterUnsubscribe/NewsletterUnsubscribe';
import Contact from './pages/contact/Contact';
import Imprint from './pages/imprint/Imprint';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';

const App: React.FC = () => {
  const [activeNav, setActiveNav] = useState<ActiveNav>(ActiveNav.HOME);
  return (
    <div className='App'>
      <Navigation activeNav={activeNav} />
      <Routes>
        <Route path='' element={<Home setActiveNav={setActiveNav} />} />
        <Route
          path='paintings'
          element={<Paintings setActiveNav={setActiveNav} />}
        />
        <Route path='about' element={<About setActiveNav={setActiveNav} />} />
        <Route
          path='newsletter'
          element={<Newsletter setActiveNav={setActiveNav} />}
        />
        <Route
          path='unsubscribe/:id'
          element={<NewsletterUnsubscribe setActiveNav={setActiveNav} />}
        />
        <Route
          path='contact'
          element={<Contact setActiveNav={setActiveNav} />}
        />
        <Route
          path='imprint'
          element={<Imprint setActiveNav={setActiveNav} />}
        />
        <Route
          path='privacy-policy'
          element={<PrivacyPolicy setActiveNav={setActiveNav} />}
        />
        <Route path='*' element={<Home setActiveNav={setActiveNav} />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
