import axios from 'axios';

// Types
import { GetPaintingsResult } from '../types/painting/GetPaintingsResult';
import { NewsletterInput } from '../types/newsletter/NewsletterInput';
import { ContactInput } from '../types/contact/ContactInput';

const baseUrl = 'https://majunkerart-backend.onrender.com';
// const baseUrl = 'http://192.168.0.2:8080';

export const ApiService = {
  async getPaintings(this: any, time: number, sort: number, amount: number) {
    try {
      const result = await axios.get(
        baseUrl +
          '/paintings?time=' +
          time +
          '&sort=' +
          sort +
          '&amount=' +
          amount
      );

      const data: GetPaintingsResult = result.data;

      return data;
    } catch (error: any) {
      return null;
    }
  },

  async signupNewsletter(this: any, input: NewsletterInput) {
    try {
      const result = await axios.post(baseUrl + '/newsletter', input);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      return false;
    }
  },

  async unsubscribeNewsletter(this: any, id: string) {
    try {
      const result = await axios.delete(baseUrl + '/newsletter/' + id);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      return false;
    }
  },

  async sendContactMessage(this: any, input: ContactInput) {
    try {
      const result = await axios.post(baseUrl + '/contact', input);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      return false;
    }
  },
};
