import React, { useCallback, useEffect, useState } from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';

import { ActiveNav } from '../../types/navigation/ActiveNav';
import { Painting } from '../../types/painting/Painting';
import { GetPaintingsResult } from '../../types/painting/GetPaintingsResult';
import { ApiService } from '../../services/Api-Service';
import IconLoader from '../../components/atoms/iconLoader/IconLoader';
import HomePaintingCardTwo from '../../components/molecules/paintings/homePaintingCardTwo/HomePaintingCardTwo';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const Home: React.FC<Props> = ({ setActiveNav }: Props) => {
  const [paintings, setPaintings] = useState<Painting[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPaintings = useCallback(async () => {
    const res: GetPaintingsResult | null = await ApiService.getPaintings(
      Date.now(),
      0,
      10
    );

    if (res) {
      setPaintings(res.paintings);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (paintings.length === 0) {
      getPaintings();
    }
  }, [paintings.length, getPaintings]);

  useEffect(() => {
    setActiveNav(ActiveNav.HOME);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <div className='Home'>
      <Helmet>
        <title>MARTINA JUNKER ART – Florale Malerei / Floral Paintings</title>
      </Helmet>
      <div className='HomeInner'>
        {isLoading ? <IconLoader /> : null}
        {/* <div className='custom-row'>
          {paintings.map((painting: Painting, index: number) => (
            <div className='custom-col' key={index}>
              <HomePaintingCard painting={painting} />
            </div>
          ))}
        </div> */}
        <div className='custom-row'>
          {paintings.map((painting: Painting, index: number) => (
            <div className='custom-two-col' key={index}>
              <HomePaintingCardTwo painting={painting} index={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
