import React from 'react';
import './PaintingsInfiniteScroll.css';

import InfiniteScroll from 'react-infinite-scroll-component';

// Types
import { Painting } from '../../../../types/painting/Painting';
import { ColorScheme } from '../../../../types/painting/ColorScheme';
import PaintingCard from '../../../molecules/paintings/paintingCard/PaintingCard';
import IconLoader from '../../../atoms/iconLoader/IconLoader';

type Props = {
  paintings: Painting[];
  hasMore: boolean;
  color: ColorScheme;
  getMorePaintings: (
    w: Painting[],
    x: ColorScheme,
    y: boolean,
    z: number
  ) => void;
};

const PaintingsInfiniteScroll: React.FC<Props> = ({
  paintings,
  hasMore,
  color,
  getMorePaintings,
}: Props) => {
  return (
    <div className='PaintingsInfiniteScroll'>
      <InfiniteScroll
        dataLength={paintings != null ? paintings.length : 0}
        next={() =>
          getMorePaintings(
            paintings,
            color,
            false,
            paintings.length > 0
              ? paintings[paintings.length - 1].timestamp
              : Date.now()
          )
        }
        hasMore={hasMore}
        loader={<IconLoader />}
      >
        <div className='custom-row'>
          {paintings != null
            ? paintings.map((painting: Painting, index: number) => (
                <div key={index} className='custom-col'>
                  <PaintingCard painting={painting} />
                </div>
              ))
            : null}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default PaintingsInfiniteScroll;
