import React, { useEffect, useState } from 'react';
import './Newsletter.css';
import { ActiveNav } from '../../types/navigation/ActiveNav';
import TextVerificationInput from '../../components/atoms/textVerificationInput/TextVerificationInput';
import { ApiService } from '../../services/Api-Service';
import IconLoader from '../../components/atoms/iconLoader/IconLoader';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const Newsletter: React.FC<Props> = ({ setActiveNav }: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [wrongError, setWrongError] = useState(false);

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActiveNav(ActiveNav.NEWSLETTER);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
      case 'email': {
        setEmail(e.target.value);
        setEmailError('');
        break;
      }
    }
    setWrongError(false);
  };

  const triggerSend = () => {
    setWrongError(false);
    const isValid = checkForError();
    if (isValid) {
      send();
    }
  };

  const checkForError = () => {
    let isValid = true;

    if (name === '') {
      setNameError('Please enter a name.');
      isValid = false;
    }

    if (email === '') {
      setEmailError('Please enter an email.');
      isValid = false;
    } else if (!/[^@\s]+@[^@\s]+\.[^@\s]+/.test(email)) {
      setEmailError('Please enter a valid email.');
      isValid = false;
    }

    return isValid;
  };

  const send = async () => {
    setIsLoading(true);

    const inputObj = {
      name: name,
      email: email,
    };
    const res: boolean = await ApiService.signupNewsletter(inputObj);

    if (res) {
      setIsSuccessful(true);
    } else {
      setWrongError(true);
    }

    setIsLoading(false);
  };

  return (
    <div className='Newsletter'>
      <Helmet>
        <title>Newsletter - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='NewsletterInner'>
        {!isSuccessful ? (
          <>
            <div className='top-text'>
              <span>Sign up for my newsletter</span>
            </div>
            <TextVerificationInput
              className='text-input'
              value={name}
              placeholder='Name'
              name='name'
              errorMessage={nameError}
              onChange={onChange}
            />
            <TextVerificationInput
              className='text-input'
              value={email}
              placeholder='Email'
              name='email'
              errorMessage={emailError}
              onChange={onChange}
            />
            <div
              className={
                isLoading || wrongError ? 'loading-error-container' : ''
              }
            >
              {wrongError ? (
                <div className='wrong-container'>
                  <span>Something went wrong!</span>
                  <span>Please try again.</span>
                </div>
              ) : null}
              {isLoading ? <IconLoader /> : null}
            </div>
            <button onClick={triggerSend} className='signup-button'>
              Sign up
            </button>
          </>
        ) : (
          <div className='success-text'>
            <span>Thank you for signing up to my newsletter!</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
