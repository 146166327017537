import React, { useContext, useState } from 'react';
import './Navigation.css';

import { Link } from 'react-router-dom';

// Providers
import { ViewportContext, Viewport } from '../../../providers/ViewportProvider';

// Images
import Menu from '../../../static/images/menu.svg';
import Close from '../../../static/images/close.svg';

// Types
import { ActiveNav } from '../../../types/navigation/ActiveNav';

type Props = {
  activeNav: ActiveNav;
};

const Navigation: React.FC<Props> = ({ activeNav }: Props) => {
  const { viewport } = useContext(ViewportContext);
  const [showNav, setShowNav] = useState(false);
  const [showNavBackground, setShowNavBackground] = useState(false);
  const [showNavBackgroundAnimation, setShowNavBackgroundAnimation] =
    useState(false);
  const [hideNavBackgroundAnimation, setHideNavBackgroundAnimation] =
    useState(false);

  // const toggleNav = () => {
  //   setShowNav(!showNav);
  // };

  const toggleNav = () => {
    if (showNav === true) {
      toggleHideNavBackgroundAnimation();
      toggleShowNavBackground(!showNav);
    } else {
      toggleShowNavBackgroundAnimation();
      toggleShowNavBackground(!showNav);
    }
    setShowNav(!showNav);
  };

  const toggleShowNavBackground = (isDirect: boolean) => {
    if (isDirect) {
      setShowNavBackground(!showNavBackground);
    } else {
      setTimeout(() => {
        setShowNavBackground(!showNavBackground);
      }, 480);
    }
  };

  const toggleHideNavBackgroundAnimation = () => {
    setHideNavBackgroundAnimation(true);
    setTimeout(() => {
      setHideNavBackgroundAnimation(false);
    }, 500);
  };

  const toggleShowNavBackgroundAnimation = () => {
    setShowNavBackgroundAnimation(true);
    setTimeout(() => {
      setShowNavBackgroundAnimation(false);
    }, 500);
  };

  return (
    <div className='Navigation'>
      <div className='NavigationInner'>
        {viewport === Viewport.DESKTOP ? (
          <>
            <span className='nav-title'>
              Martina Junker <span className='nav-title-art'>Art</span>
            </span>
            <div className='nav-link-row'>
              <Link
                to='/'
                className={`nav-link ${
                  activeNav === ActiveNav.HOME ? 'active-link' : ''
                }`}
              >
                Home
              </Link>
              <Link
                to='/paintings'
                className={`nav-link ${
                  activeNav === ActiveNav.PAINTINGS ? 'active-link' : ''
                }`}
              >
                Paintings
              </Link>
              <Link
                to='/about'
                className={`nav-link ${
                  activeNav === ActiveNav.ABOUT ? 'active-link' : ''
                }`}
              >
                About
              </Link>
              <Link
                to='/newsletter'
                className={`nav-link ${
                  activeNav === ActiveNav.NEWSLETTER ? 'active-link' : ''
                }`}
              >
                Newsletter
              </Link>
              <Link
                to='/contact'
                className={`nav-link ${
                  activeNav === ActiveNav.CONTACT ? 'active-link' : ''
                }`}
              >
                Contact
              </Link>
            </div>
          </>
        ) : (
          <>
            <div
              className={`mobile-nav-container ${
                showNav ? 'mobile-active-nav' : ''
              }`}
            >
              <div className='mobile-left-nav-element'></div>
              <div className='mobile-middle-nav-element'>
                <span className='mobile-nav-title'>
                  Martina Junker{' '}
                  <span className='mobile-nav-title-art'>Art</span>
                </span>
              </div>
              <div className='mobile-right-nav-element'>
                <button className='mobile-menu-button' onClick={toggleNav}>
                  <img src={Menu} alt='Menu' />
                </button>
              </div>
              {showNavBackground ? (
                <div
                  className={`mobile-nav-dark-background ${
                    showNavBackgroundAnimation ? 'mobile-nav-close-show' : ''
                  } ${
                    hideNavBackgroundAnimation ? 'mobile-nav-close-hide' : ''
                  }`}
                  onClick={toggleNav}
                ></div>
              ) : null}
              <div className='mobile-navbar'>
                <div className='mobile-nav-content'>
                  <div className='mobile-close-container'>
                    <img
                      src={Close}
                      alt='close'
                      onClick={toggleNav}
                      className='mobile-close-image'
                    />
                  </div>
                  <div className='mobile-link-column'>
                    <Link
                      to='/'
                      className={`mobile-nav-link ${
                        activeNav === ActiveNav.HOME ? 'mobile-active-link' : ''
                      }`}
                      onClick={toggleNav}
                    >
                      Home
                    </Link>
                    <Link
                      to='/paintings'
                      className={`mobile-nav-link ${
                        activeNav === ActiveNav.PAINTINGS
                          ? 'mobile-active-link'
                          : ''
                      }`}
                      onClick={toggleNav}
                    >
                      Paintings
                    </Link>
                    <Link
                      to='/about'
                      className={`mobile-nav-link ${
                        activeNav === ActiveNav.ABOUT
                          ? 'mobile-active-link'
                          : ''
                      }`}
                      onClick={toggleNav}
                    >
                      About
                    </Link>
                    <Link
                      to='/newsletter'
                      className={`mobile-nav-link ${
                        activeNav === ActiveNav.NEWSLETTER
                          ? 'mobile-active-link'
                          : ''
                      }`}
                      onClick={toggleNav}
                    >
                      Newsletter
                    </Link>
                    <Link
                      to='/contact'
                      className={`mobile-nav-link ${
                        activeNav === ActiveNav.CONTACT
                          ? 'mobile-active-link'
                          : ''
                      }`}
                      onClick={toggleNav}
                    >
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navigation;
