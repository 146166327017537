import React from 'react';
import './IconLoader.css';

const IconLoader: React.FC = () => {
  return (
    <div className='IconLoader'>
      <span className='title'>
        Martina Junker <span className='title-art'>Art</span> ...
      </span>
    </div>
  );
};

export default IconLoader;
