import React, { useEffect, useState } from 'react';
import './Contact.css';

// Components
import IconLoader from '../../components/atoms/iconLoader/IconLoader';
import TextVerificationInput from '../../components/atoms/textVerificationInput/TextVerificationInput';

// Services
import { ApiService } from '../../services/Api-Service';

// Types
import { ContactInput } from '../../types/contact/ContactInput';
import { ActiveNav } from '../../types/navigation/ActiveNav';
import TextAreaVerificationInput from '../../components/atoms/textAreaVerificationInput/TextAreaVerificationInput';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const Contact: React.FC<Props> = ({ setActiveNav }: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [wrongError, setWrongError] = useState(false);

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActiveNav(ActiveNav.CONTACT);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
      case 'email': {
        setEmail(e.target.value);
        setEmailError('');
        break;
      }
      case 'subject': {
        setSubject(e.target.value);
        setSubjectError('');
        break;
      }
      case 'message': {
        setMessage(e.target.value);
        setMessageError('');
        break;
      }
    }
    setWrongError(false);
  };

  const triggerSend = () => {
    setWrongError(false);
    const isValid = checkForError();
    if (isValid) {
      send();
    }
  };

  const checkForError = () => {
    let isValid = true;

    if (name === '') {
      setNameError('Please enter a name.');
      isValid = false;
    }

    if (email === '') {
      setEmailError('Please enter an email.');
      isValid = false;
    } else if (!/[^@\s]+@[^@\s]+\.[^@\s]+/.test(email)) {
      setEmailError('Please enter a valid email.');
      isValid = false;
    }

    if (subject === '') {
      setSubjectError('Please enter a subject.');
      isValid = false;
    }

    if (message === '') {
      setMessageError('Please enter a message.');
      isValid = false;
    }

    return isValid;
  };

  const send = async () => {
    setIsLoading(true);

    const inputObj: ContactInput = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    const res: boolean = await ApiService.sendContactMessage(inputObj);

    if (res) {
      setIsSuccessful(true);
    } else {
      setWrongError(true);
    }

    setIsLoading(false);
  };

  return (
    <div className='Contact'>
      <Helmet>
        <title>Contact - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='ContactInner'>
        {!isSuccessful ? (
          <>
            <div className='top-text'>
              <span>Contact me</span>
            </div>
            <TextVerificationInput
              className='text-input'
              value={name}
              placeholder='Name'
              name='name'
              errorMessage={nameError}
              onChange={onChange}
            />
            <TextVerificationInput
              className='text-input'
              value={email}
              placeholder='Email'
              name='email'
              errorMessage={emailError}
              onChange={onChange}
            />
            <TextVerificationInput
              className='text-input'
              value={subject}
              placeholder='Subject'
              name='subject'
              errorMessage={subjectError}
              onChange={onChange}
            />
            <TextAreaVerificationInput
              className='text-input'
              value={message}
              placeholder='Message'
              name='message'
              errorMessage={messageError}
              onChange={onChange}
            />
            <div
              className={
                isLoading || wrongError ? 'loading-error-container' : ''
              }
            >
              {wrongError ? (
                <div className='wrong-container'>
                  <span>Something went wrong!</span>
                  <span>Please try again.</span>
                </div>
              ) : null}
              {isLoading ? <IconLoader /> : null}
            </div>
            <button onClick={triggerSend} className='send-button'>
              Send
            </button>
          </>
        ) : (
          <div className='success-text'>
            <span>Thank you for your message!</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
