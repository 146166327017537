import React, { useEffect } from 'react';
import './About.css';
import { ActiveNav } from '../../types/navigation/ActiveNav';

import Mama from '../../static/images/mama-about.jpeg';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const About: React.FC<Props> = ({ setActiveNav }: Props) => {
  useEffect(() => {
    setActiveNav(ActiveNav.ABOUT);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <div className='About'>
      <Helmet>
        <title>About - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='AboutInner'>
        <div className='image-container'>
          <img src={Mama} alt='Majunkerart' />
        </div>
        <div className='content-container'>
          <div className='german-container'>
            <p>
              In meinen Bildern möchte ich das Gefühl widerspiegeln, das auf
              einer Wanderung durch die Natur entsteht, bei der man sich
              unbeschwert und frei fühlt, wie ein Kind auf Entdeckungstour.
              Plötzlich sieht man wieder die kleinen, eher unscheinbaren Dinge,
              in denen so viel Schönheit steckt und empfindet Freude daran.
            </p>
            <p>
              Nach der Ausbildung zur Modegraphikerin an der Meisterschule für
              Mode München, arbeitete ich mehrere Jahre als Graphikerin in
              verschiedenen Werbeagenturen. Danach forderten vier Kinder meine
              ganze Aufmerksamkeit und Kreativität. Jetzt lebe ich mit meinem
              Mann in Quickborn, in der Nähe von Hamburg, die Kinder sind groß
              und flügge und ich habe wieder Zeit mich dem Malen zu widmen.
            </p>
          </div>
          <div className='english-container'>
            <p>
              In my pictures I want to reflect the feeling that arises on a hike
              through nature, where you feel carefree and free, like a child on
              discovery tour. Suddenly you see again the small, rather
              inconspicuous things, in which there is so much beauty and feel
              joy in it.
            </p>
            <p>
              After training as a fashion graphic designer at the Master School
              of Fashion in Munich, I worked for several years as a graphic
              designer in various advertising agencies. After that, four
              children demanded all my attention and creativity.
            </p>
            <p>
              I now live with my husband in Quickborn, near Hamburg, the
              children are grown and fledged and I have time again to devote
              myself to painting.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
