import React, { useEffect, useState } from 'react';
import './NewsletterUnsubscribe.css';

import { useParams } from 'react-router-dom';

// Services
import { ApiService } from '../../services/Api-Service';

// Components
import IconLoader from '../../components/atoms/iconLoader/IconLoader';

// Types
import { ActiveNav } from '../../types/navigation/ActiveNav';
import { Helmet } from 'react-helmet';

type Props = {
  setActiveNav: (x: ActiveNav) => void;
};

const NewsletterUnsubscribe: React.FC<Props> = ({ setActiveNav }: Props) => {
  let { id } = useParams();
  const [wrongError, setWrongError] = useState(false);

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActiveNav(ActiveNav.NONE);
  }, [setActiveNav]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const send = async () => {
    setWrongError(false);
    setIsLoading(true);

    const res: boolean = await ApiService.unsubscribeNewsletter(id ? id : '');

    if (res) {
      setIsSuccessful(true);
    } else {
      setWrongError(true);
    }

    setIsLoading(false);
  };

  return (
    <div className='NewsletterUnsubscribe'>
      <Helmet>
        <title>Unsubscribe - MARTINA JUNKER ART</title>
      </Helmet>
      <div className='NewsletterUnsubscribeInner'>
        {!isSuccessful ? (
          <>
            <div className='top-text'>
              <span>Do you really want to unsubscribe from my newsletter?</span>
            </div>
            <div
              className={
                isLoading || wrongError ? 'loading-error-container' : ''
              }
            >
              {wrongError ? (
                <div className='wrong-container'>
                  <span>Something went wrong!</span>
                  <span>Please try again.</span>
                </div>
              ) : null}
              {isLoading ? <IconLoader /> : null}
            </div>
            <button onClick={send} className='signup-button'>
              Unsubscribe
            </button>
          </>
        ) : (
          <div className='success-text'>
            <span>Successfully unsubscribed!</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterUnsubscribe;
